<script>
// @ts-check
/* eslint-disable camelcase */ /* eslint-disable no-param-reassign */
import Pagination from '@/components/UIs/Pagination.vue';
import { computed, reactive, watchEffect } from 'vue';
import followApi from '@/apis/followApi';
import userApi from '@/apis/userApi';
import { globalState } from '@/stores/global';
import FollowingCardInfo from '@/components/users/FollowingCardInfo.vue';
import router from '@/router';
import { followQuery, useFollowingsWithUserInfo, usePagination } from '@/hooks/query';
import { queryClient } from '@/main';

const PER_PAGE = 20;
export default {
	components: { Pagination, FollowingCardInfo },
	setup() {
		const followingsQuery = useFollowingsWithUserInfo(globalState.user.id);

		/** @param {{ user_id: t.User['docId'], follow_user_id: t.User['docId'] }} params */
		const handleToggleFollowAction = async ({ user_id, follow_user_id }) => {
			try {
				await followApi.toggleFollowUser({ user_id, follow_user_id });
			} catch (error) {
				console.log(error);
			} finally {
				queryClient.invalidateQueries('follows:followings');
			}
		};

		const { visibleList, listLength } = usePagination(
			computed(() => {
				return followingsQuery.data.value || [];
			}),
			{ PER_PAGE }
		);

		return {
			globalState,
			followings: followingsQuery.data,
			status: followingsQuery.status,
			handleToggleFollowAction,
			listLength,
			visibleList,
			CONFIG: {
				PER_PAGE
			}
		};
	}
};
</script>

<template>
	<div class="flex flex-col flex-1 text-xl font-medium">
		<div class="w-full">
			<div class="lg:my-16 font-bold lg:text-33px lg:ml-14 p-4 text-2xl">フォロワー</div>
			<div>
				<ul>
					<following-card-info v-for="followingUser in visibleList" :key="followingUser.docId" :following-user="followingUser" @toggle-follow-action="handleToggleFollowAction">
					</following-card-info>
					<hr class="border border-gray-300" />
				</ul>
				<div v-if="status === 'success' && listLength === 0" class="text-center m-3">
					データがありません
				</div>
				<pagination v-if="listLength" goto-route-name="follow_list" :total-record="listLength" :per-page="CONFIG.PER_PAGE" class="mb-8" />
			</div>
		</div>
	</div>
</template>
