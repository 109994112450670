<script>
import { globalState } from '@/stores/global';
import DefaultAvatar from '@/assets/icons/DefaultAvatar.vue';

export default {
	components: { DefaultAvatar },
	props: {
		followingUser: {
			/** @type {import('vue').PropType<NonNullable<t.ELement<import('@/views/FollowList.vue').PageFollowingUsers>>>} */
			type: Object,
			default: () => {}
		}
	},
	emits: ['toggle-follow-action'],
	setup() {
		return {
			globalUser: globalState.user
		};
	}
};
</script>
<template>
	<div class="flex items-center p-4 border-t flex-col lg:flex-row">
		<div class="flex flex-row justify-between w-full lg:w-1/12 lg:mr-3 items-center">
			<div class="flex flex-row items-center">
				<router-link :to="{ name: 'my_page', params: { userId: followingUser?.follow_user_id } }">
					<div class="w-16 h-16 cursor-pointer mr-4 lg:mr-0">
						<img v-if="followingUser.user?.icon_img" :src="followingUser.user?.icon_img" class="object-cover w-full h-full rounded-full " />
						<default-avatar v-if="!followingUser.user?.icon_img" />
					</div>
				</router-link>
				<div class="lg:hidden">
					<router-link :to="{ name: 'my_page', params: { userId: followingUser?.follow_user_id } }">
						<div class="text-base lg:text-xl font-bold">{{ followingUser.user?.nickname }}</div>
					</router-link>
				</div>
			</div>
			<div class="lg:hidden">
				<button
					class="text-base font-semibold text-white bg-center bg-no-repeat bg-contain lg:h-19 h-50px w-140px bg-button-blue lg:w-44 hover:bg-button-yellow focus:outline-none hover:text-blue-400"
					@click="
						$emit('toggle-follow-action', {
							user_id: globalUser.id,
							follow_user_id: followingUser?.follow_user_id
						})
					"
				>
					フォロー中
				</button>
			</div>
		</div>
		<div class="w-full lg:w-8/12">
			<router-link :to="{ name: 'my_page', params: { userId: followingUser?.follow_user_id } }">
				<div class="text-base lg:text-xl font-bold hidden lg:block">{{ followingUser.user?.nickname }}</div>
				<div class="mt-1 font-medium text-xs lg:text-base">
					{{ followingUser.user?.greeting?.substring(0, 50) }}
					<span v-if="followingUser.user?.greeting?.length > 50">...</span>
				</div>
			</router-link>
		</div>
		<div class="w-2/5 lg:w-3/12 hidden lg:block">
			<div class="text-right">
				<button
					class="text-base font-semibold text-white bg-center bg-no-repeat bg-contain lg:h-19 h-50px w-140px bg-button-blue lg:w-44 hover:bg-button-yellow focus:outline-none hover:text-blue-400"
					@click="
						$emit('toggle-follow-action', {
							user_id: globalUser.id,
							follow_user_id: followingUser?.follow_user_id
						})
					"
				>
					フォロー中
				</button>
			</div>
		</div>

		<!-- <div class="w-1/5 lg:w-1/12">
			<router-link :to="{ name: 'my_page', params: { userId: followingUser?.follow_user_id } }">
				<div class="w-16 h-16 cursor-pointer">
					<img
						v-if="followingUser.user?.icon_img"
						:src="followingUser.user?.icon_img"
						class="object-cover w-full h-full rounded-full "
					/>
					<default-avatar v-if="!followingUser.user?.icon_img" />
				</div>
			</router-link>
		</div>
		<div class="w-2/5 lg:w-8/12">
			<router-link :to="{ name: 'my_page', params: { userId: followingUser?.follow_user_id } }">
				<div class="text-base font-bold ">{{ followingUser.user?.nickname }}</div>
				<div class="mt-1 font-medium">
					{{ followingUser.user?.greeting?.substring(0, 50) }}
					<span v-if="followingUser.user?.greeting?.length > 50">...</span>
				</div>
			</router-link>
		</div>
		<div class="w-2/5 lg:w-3/12">
			<div class="text-right">
				<button
					class="text-base font-semibold text-white bg-center bg-no-repeat bg-contain lg:h-19 h-50px w-140px bg-button-blue lg:w-44 hover:bg-button-yellow focus:outline-none hover:text-blue-400"
					@click="
						$emit('toggle-follow-action', {
							user_id: globalUser.id,
							follow_user_id: followingUser?.follow_user_id
						})
					"
				>
					フォロー中
				</button>
			</div>
		</div> -->
	</div>
</template>
